import { Route } from "react-router-dom";
import { PackagePlan } from "../apps/package-plan";
import { Students } from "../apps/students";
import { TestResult } from "../apps/result";
import { StudentSettings } from "../apps/settings/StudentSettings";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
   <Route path="/package-plan" element={<PackagePlan />} />
   <Route path="/students" element={<Students />} />
   <Route path="/test-result" element={<TestResult />} />
   <Route path="/student/settings" element={<StudentSettings />} />
   <Route path="/teacher/settings" element={<StudentSettings />} />

  </>,
];