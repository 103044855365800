import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const navigateToPricing = () => {
    navigate("/", { state: { scrollToPricing: true } });
  };

  return (
    <Box as="footer" bg="gray.50" py={10} boxShadow={"md"}>
      <Container maxW="container.xl">
        <Flex
          justify="space-between"
          align="center"
          direction={{ base: "column", md: "row" }}
        >
          <HStack spacing={8} mb={{ base: 4, md: 0 }}>
            <Button
              colorScheme="teal"
              variant="link"
              onClick={navigateToPricing}
            >
              Pricing
            </Button>
            <Button
              colorScheme="teal"
              variant="link"
              as={"a"}
              href="/terms-conditions"
              ml={{ base: 0, md: -9 }}
            >
              Terms Conditions
            </Button>
            <Button
              colorScheme="teal"
              variant="link"
              as={"a"}
              href="/privacy-policy"
              ml={{ base: 0, md: -9 }}
            >
              Privacy Policy
            </Button>
            <Button
              ml={{ base: 0, md: -9 }}
              colorScheme="teal"
              variant="link"
              as={"a"}
              href="/contact-us"
            >
              Contact Us
            </Button>
          </HStack>
          <HStack spacing={4}>
            {/* <Icon as={FaFacebook} boxSize={6} />
            <Icon as={FaTwitter} boxSize={6} />
            <Icon as={FaLinkedin} boxSize={6} />
            <Icon as={FaInstagram} boxSize={6} /> */}
          </HStack>
        </Flex>
        <Text textAlign="center" mt={4}>
          {`© ${currentYear} PassEnglishTest. All rights reserved`}
        </Text>
      </Container>
    </Box>
  );
};
