import {
  ChakraProvider,
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown, FiSettings } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import { IconType } from "react-icons";
import { Outlet, useLocation } from "react-router-dom";
import { signOutAction } from "../redux/slice/auth/authSlice";
import { useAppDispatch } from "../redux/hooks/useTypedSelector";
import { FaBook, FaEdit, FaUsers } from "react-icons/fa";
import { useUser } from "../hooks/use-user";
import theme from "../theme";
import { Logo } from "../components/Logo";
import { daysRemaining, isDateExpired } from "../utils/helperFuntions";

type LinkItemProps = {
  name: string;
  href?: string;
  icon: IconType;
  children?: Array<LinkItemProps>;
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  href?: string;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const adminLinkItems: Array<LinkItemProps> = [
  { name: "Dashboard", href: "/dashboard", icon: MdDashboard },
  {
    name: "Question Bank",
    icon: FaBook,
    children: [
      { name: "Listening", href: "/admin-listening", icon: FaBook },
      { name: "Reading", href: "/admin-reading", icon: FaBook },
      { name: "Writing", href: "/admin-writing", icon: FaBook },
      { name: "Speaking", href: "/admin-speaking", icon: FaBook },
    ],
  },
  // { name: "Mock Test", href: "admin/mock-test", icon: FaEdit },
  { name: "Students", href: "all-students", icon: FaUsers },
  { name: "Teachers", href: "teachers", icon: FaUsers },
  { name: "Settings", href: "admin/settings", icon: FiSettings },
];
const studentLinkItems: Array<LinkItemProps> = [
  { name: "Dashboard", href: "/dashboard", icon: MdDashboard },
  { name: "Mock Test", href: "/mock-test", icon: FaEdit },
  { name: "Package Plan", href: "/package-plan", icon: FiMenu },
  { name: "Settings", href: "student/settings", icon: FiSettings },
];
const teacherLinkItems: Array<LinkItemProps> = [
  { name: "Dashboard", href: "/dashboard", icon: MdDashboard },
  { name: "Mock Test", href: "/mock-test", icon: FaEdit },
  { name: "Test Result", href: "/test-result", icon: FaBook },
  { name: "Package Plan", href: "/package-plan", icon: FiMenu },
  { name: "Students", href: "students", icon: FaUsers },
  { name: "Settings", href: "teacher/settings", icon: FiSettings },
];

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const user = useUser();
  const accountType = user?.accountType;
  if (!user) {
    window.location.href = "/";
  }
  let linkItems: any;
  switch (accountType) {
    case "admin":
      linkItems = adminLinkItems;
      break;
    case "student":
      linkItems = studentLinkItems;
      break;
    case "teacher":
      linkItems = teacherLinkItems;
      break;
    default:
      linkItems = [];
  }

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      boxShadow="md"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Logo size={24} />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {linkItems.map((link: any) =>
        link.children ? (
          <Menu key={link.name} closeOnSelect={false}>
            <MenuButton
              // as={Button}
              borderRadius={"1px"}
              bg={"transparent"}
              color={"gray.600"}
              w={"100%"}
              textAlign={"left"}
              pl={0}
              _hover={{
                mx: "0",
                bg: "#f6f9ff",
                color: "#4154f1",
                boxShadow: "md",
              }}
            >
              <NavItem icon={link.icon}>{link.name}</NavItem>
            </MenuButton>
            <MenuList borderRadius={"1px"}>
              <MenuOptionGroup>
                {link.children.map((child: any) => (
                  <MenuItemOption key={child.name} as="a" href={child.href}>
                    {child.name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        ) : (
          <NavItem key={link.name} icon={link.icon} as="a" href={link.href}>
            {link.name}
          </NavItem>
        ),
      )}
    </Box>
  );
};

const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
  const location = useLocation();
  const isActive =
    location.pathname === href || location.pathname.includes(href ?? "");

  return (
    <Box
      as="a"
      href={href}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p={2}
        mx={4}
        w={isActive ? "94%" : "100%"}
        borderRadius={1}
        cursor="pointer"
        bg={isActive ? "#f6f9ff" : "transparent"}
        color={isActive ? "#4154f1" : "gray.600"}
        _hover={{
          mx: "0",
          bg: "#f6f9ff",
          color: "#4154f1",
          boxShadow: "md",
          w: "99%",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "#4154f1",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const logout = (e: { preventDefault: () => void }) => {
    dispatch(signOutAction());
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      boxShadow="md"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Box display={{ base: "flex", md: "none" }}>
        <Logo size={39} />
      </Box>

      <HStack spacing={{ base: "0", md: "6" }}>
        {isDateExpired(user?.exp_date) ? (
          <>
            <Box mt={4} as="a" href="/package-plan">
              <Alert status="warning" mt={4} borderRadius={9} fontSize={12}>
                <AlertIcon />
                Your subscription has expired. You can only practice using one
                free mock test. Please upgrade your plan to practice all the
                mock tests.
              </Alert>
            </Box>
          </>
        ) : (
          <Flex alignItems={"center"}>
            <Box>
              <Alert status="info" mt={4} borderRadius={4} bg={"blue.50"}>
                {/* <AlertIcon /> */}
                Expiry Date: {user?.exp_date}
              </Alert>
            </Box>
            <Box ml={9}>
              <Alert status="info" mt={4} borderRadius={4} bg={"blue.50"}>
                {/* <AlertIcon /> */}
                Remaining Days: {daysRemaining(user?.exp_date || "") ?? 0}
              </Alert>
            </Box>
          </Flex>
        )}
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text color="gray.600">{user?.firstName}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuDivider />
              <MenuItemOption onClick={logout}>Sign out</MenuItemOption>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const AuthLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={"gray.100"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <Outlet />
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default AuthLayout;
