import { useState, useEffect, FC } from "react";
import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import {
  useAppSelector,
} from "../redux/hooks/useTypedSelector";
import { FaBook } from "react-icons/fa";
import { encryptString } from "../utils/encryption";
import { WritingQuestion } from "../types/writing-types";

// Helper function to check if questions have testNum and non-empty feedback
const hasFeedbackForTest = async (questions: WritingQuestion[], testNum: number) => {
  const result = await questions.some((question) => 
    Number(question.testNum) === testNum && question.feedback
  );
  console.log("testNum: ",testNum);
  console.log("result: ",result);

  return result;
};

type CompleteTestButtonProps = {
  testNum: number;
  questions: WritingQuestion[];
};

export const CompleteTestButton: FC<CompleteTestButtonProps> = ({ testNum, questions }) => {
  const [hasTestResult, setHasTestResult ] = useState<boolean>(false);
  const { isLoading } = useAppSelector(
    (state: { mockTestResult: any }) => state.mockTestResult,
  );

  useEffect(() => {
    const checkIsResult = async () => {
      setHasTestResult(await hasFeedbackForTest(questions, testNum));
    };
    checkIsResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testNum,questions]);


  if(isLoading){
    return <Spinner />
  }

  return (
    <Box w={"100%"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
        <Button
          as={"a"}
          href={`/complete-mock-test/${encryptString(testNum.toString())}`}
          bg="#319795"
          color="white"
          size="lg"
          w={{ base: "100%", md: "auto" }}
        >
          Complete Test
        </Button>
        {hasTestResult && (
          <Box>
            <Button
              as={"a"}
              href={`/complete-test-result/${encryptString(testNum.toString())}`}
              colorScheme="teal"
              variant="ghost"
              size="lg"
              w={{ base: "100%", md: "100%" }}
            >
              <Icon
                mr="4"
                _groupHover={{
                  color: "000",
                }}
                as={FaBook}
              />
              Result
            </Button>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
};
