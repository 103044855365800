import {
  Box,
  Container,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

export const FAQS = () => {
  const accordionStyle = {
    boxShadow: "md",
    p: "2",
    rounded: "md",
    borderTop: "2px #FFF solid",
    mb: "3",
  };
  return (
    <Box as="section" py={20}>
      <Container maxW="container.xl">
        <Heading size="xl" textAlign="center" mb={10}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowToggle>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box bg="white">
              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What is PassEnglishTest?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  PassEnglishTest is a comprehensive platform designed to help
                  you prepare for the CELPIP test with realistic practice tests,
                  detailed analytics, and interactive lessons.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How can PassEnglishTest help me prepare for the CELPIP test?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  PassEnglishTest offers a variety of practice tests and
                  interactive lessons to help you familiarize yourself with the
                  test format and improve your skills.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What features are included in each plan?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Our plans range from basic features to advanced options
                  including personal coaching and additional practice tests.
                  Check our pricing section for more details.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How do I track my progress?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Use our detailed analytics to monitor your progress and
                  identify areas for improvement. Our platform provides insights
                  to help you achieve your best score.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How can I track my progress using CELPIP exam practice mock
                    tests?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Our platform offers detailed analytics for each mock test,
                  helping you identify strengths and areas for improvement,
                  track your progress over time, and focus on areas that need
                  more practice.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Can I use CELPIP exam practice mock tests for immigration or
                    citizenship requirements?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Practice mock tests are designed to help you prepare for the
                  actual CELPIP exam and cannot be used as a substitute for
                  official test results in immigration or citizenship
                  applications.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What should I bring on the day of the CELPIP test?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  On the day of the CELPIP test, you should bring a valid,
                  government-issued photo ID, such as a passport. Make sure to
                  arrive at the test center at least 45 minutes before your
                  scheduled test time.
                </AccordionPanel>
              </AccordionItem>
            </Box>
            <Box bg="white">
              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Why is CELPIP needed?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  CELPIP is required for those looking to prove their English
                  proficiency for immigration, citizenship, or professional
                  designation in Canada.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Can CELPIP be taken from a remote location?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Currently, CELPIP can only be taken in designated test centers
                  and cannot be taken remotely.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    When will the result be out?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  CELPIP results are typically available online within 3 to 4
                  business days after the test date.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What is the test cost, and how can I pay for it?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text color={"#fd6406"} fontWeight={600}>
                    - CELPIP - General Test $280 + Taxes (where applicable)
                  </Text>
                  <Text ml={8}>
                    Evaluates test taker’s English speaking, reading, listening,
                    and writing skills and is used for permanent residence
                    applications and professional designations. This is the
                    Canadian price, international pricing will vary. Learn more
                    about international pricing{" "}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="https://www.celpip.ca/take-celpip/overview/"
                      target="_blank"
                    >
                      here
                    </Text>
                    .
                  </Text>
                  <Text color={"#0c7265"} mt={3} fontWeight={600}>
                    - CELPIP - General LS Test $195 + Taxes
                  </Text>
                  <Text ml={8}>
                    Evaluates test taker’s English speaking and listening skills
                    and is used for citizenship applications and professional
                    designation.
                  </Text>

                  <Text mt={9}>
                    Payment can be made online via credit card during the
                    registration process.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What is the minimum passing score?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  The minimum passing score depends on the purpose of the test,
                  such as immigration or citizenship. Specific score
                  requirements should be checked with the relevant authorities.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What are the common mistakes to avoid when taking CELPIP
                    exam practice mock tests?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  Common mistakes include not timing yourself properly, not
                  reviewing your answers, and not practicing all sections of the
                  test. Our platform provides tips and strategies to help you
                  avoid these errors.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem {...accordionStyle}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Where can I book the CELPIP exam?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  You can book the CELPIP exam online through the official
                  CELPIP website, selecting a test center and date that suits
                  you.{" "}
                  <Text
                    as={"a"}
                    href="https://www.celpip.ca/take-celpip/overview/"
                    target="_blank"
                    color={"blue.500"}
                  >
                    Click here to book your test
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Box>
          </SimpleGrid>
        </Accordion>
      </Container>
    </Box>
  );
};
