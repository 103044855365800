import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "../config";
import { planPrice } from "../utils/helperFuntions";
import { useUser } from "./use-user";

// Load your publishable key from Stripe
const stripePromise = loadStripe(`pk_test_51QCtZ12KDMEJhJBybBfwuGy0Z9ec75PFa5dJ83jTATtk8an7LjTtA4BvNwjjtcTGY2XPVtOKGxbreT1GveOOHHOJ00gegyn1Pa`);

export const useStudentPlan = () => {
  const [days, setDays] = useState<number>(15);
  const user  = useUser();
  const [totalPrice, setTotalPrice] = useState<number>(49);
  const API_URL = config.baseUrl.default;

  const updatePrice = (days: number) => {
    setDays(days);
    setTotalPrice(planPrice(days));
 
  };

  const handleCheckout = async () => {

    try {

      if(user.id){
        const stripe = await stripePromise;
        const response = await fetch(`${API_URL}student-subscription-payment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ days, totalPrice, userId: user.id }),
        });
    
        const session = await response.json();
    
        // Redirect to Stripe Checkout
        const result = await stripe?.redirectToCheckout({
          sessionId: session.id,
        });
    
        if (result?.error) {
          console.error(result.error.message);
        }
      }else{
        console.error("Error making payment: user undefined error");
      }
      
     
    } catch (error: any) {
      console.error("Payment error"+error.message);
    }
   
  };

  return { stripePromise, days, totalPrice, updatePrice, handleCheckout };
};
